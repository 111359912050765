*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@font-face {
  font-family: "Cassius-Regular";
  src: url("Cassius-Regular.woff2") format("woff2"),
    url("Cassius-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 62.5%;
  line-height: 100%;
  scroll-behavior: smooth;
  background-color: black;
  height: 100%;
}

body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.font-load {
  font-family: "Cassius-Regular";
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.animations {
  position: relative;
  flex: 1 1 100%;
}

.menubar {
  /* height: 50px; */
  /* background-color: red; */
  font-size: 40px;
  line-height: 1em;
  padding: 15px;
  overflow: hidden;
  position: relative;
  flex: 0 0 1.75em;
  opacity: 0;
  transition: opacity 2s ease-in 1s;

  a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-family: "Cassius-Regular";
    margin-right: 64px;
    transition: color 0.25s;

    &.red:hover {
      color: #f05042;
    }

    &.green:hover {
      color: #5acd3c;
    }

    &.blue:hover {
      color: #3868f6;
    }
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }

  &--visible {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  body {
    flex-direction: row;
  }

  .menubar {
    writing-mode: vertical-lr;

    a {
      margin-right: 0;
      margin-bottom: 32px;
    }
  }

  .menubar-items {
    transform: rotate(180deg);
    position: absolute;
    bottom: 12px;
  }
}
