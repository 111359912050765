*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Cassius-Regular;
  src: url("Cassius-Regular.c01d9ace.woff2") format("woff2"), url("Cassius-Regular.62fa7b09.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  background-color: #000;
  font-size: 62.5%;
  line-height: 100%;
}

body {
  box-sizing: border-box;
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

.font-load {
  opacity: 0;
  height: 0;
  width: 0;
  font-family: Cassius-Regular;
  position: absolute;
}

.animations {
  flex: 100%;
  position: relative;
}

.menubar {
  opacity: 0;
  flex: 0 0 1.75em;
  padding: 15px;
  font-size: 40px;
  line-height: 1em;
  transition: opacity 2s ease-in 1s;
  position: relative;
  overflow: hidden;
}

.menubar a {
  color: #fff;
  cursor: pointer;
  margin-right: 64px;
  font-family: Cassius-Regular;
  text-decoration: none;
  transition: color .25s;
}

.menubar a.red:hover {
  color: #f05042;
}

.menubar a.green:hover {
  color: #5acd3c;
}

.menubar a.blue:hover {
  color: #3868f6;
}

@media (max-width: 768px) {
  .menubar {
    font-size: 28px;
  }
}

.menubar--visible {
  opacity: 1;
}

@media (max-width: 600px) {
  body {
    flex-direction: row;
  }

  .menubar {
    writing-mode: vertical-lr;
  }

  .menubar a {
    margin-bottom: 32px;
    margin-right: 0;
  }

  .menubar-items {
    position: absolute;
    bottom: 12px;
    transform: rotate(180deg);
  }
}

/*# sourceMappingURL=index.0f5b298b.css.map */
